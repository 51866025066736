// extracted by mini-css-extract-plugin
export var addCouponsStyle = "CartSummary-mps-module--addCouponsStyle--a815a";
export var bcCartCheckoutButton = "CartSummary-mps-module--bcCartCheckoutButton--62a82";
export var bcCartSubmitForm = "CartSummary-mps-module--bcCartSubmitForm--70b31";
export var bcCartSummary = "CartSummary-mps-module--bcCartSummary--cd34a";
export var bcCartSummaryItem = "CartSummary-mps-module--bcCartSummaryItem--9a7c5";
export var bcCartSummaryItemLabel = "CartSummary-mps-module--bcCartSummaryItemLabel--989f4";
export var bcCartSummaryItemLast = "CartSummary-mps-module--bcCartSummaryItemLast--f1218";
export var bcCartSummaryItemTotal = "CartSummary-mps-module--bcCartSummaryItemTotal--80579";
export var bcCartSummaryItemTotalLabel = "CartSummary-mps-module--bcCartSummaryItemTotalLabel--a4ae7";
export var bcCartSummaryItemValue = "CartSummary-mps-module--bcCartSummaryItemValue--d9f79";
export var bcCartSummaryTitle = "CartSummary-mps-module--bcCartSummaryTitle--f3622";
export var bcCartSummaryToggle = "CartSummary-mps-module--bcCartSummaryToggle--1defe";
export var bcCartSummaryTop = "CartSummary-mps-module--bcCartSummaryTop--ea9be";
export var bcDiscount = "CartSummary-mps-module--bcDiscount--e6eed";
export var bcMiniCartSummary = "CartSummary-mps-module--bcMiniCartSummary--6780a";
export var buttonWrapp = "CartSummary-mps-module--buttonWrapp--8cdf3";
export var promoLabel = "CartSummary-mps-module--promoLabel--5ac6f";
export var subtotal = "CartSummary-mps-module--subtotal--38e1e";
export var totalSavings = "CartSummary-mps-module--totalSavings--99c39";