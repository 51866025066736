// extracted by mini-css-extract-plugin
export var addCouponsStyle = "CartSummary-module--addCouponsStyle--e62cd";
export var bcCartCheckoutButton = "CartSummary-module--bcCartCheckoutButton--ae245";
export var bcCartSubmitForm = "CartSummary-module--bcCartSubmitForm--aca7e";
export var bcCartSummary = "CartSummary-module--bcCartSummary--71f94";
export var bcCartSummaryItem = "CartSummary-module--bcCartSummaryItem--dd8fd";
export var bcCartSummaryItemLabel = "CartSummary-module--bcCartSummaryItemLabel--95d16";
export var bcCartSummaryItemLast = "CartSummary-module--bcCartSummaryItemLast--b6b0a";
export var bcCartSummaryItemTotal = "CartSummary-module--bcCartSummaryItemTotal--6ea87";
export var bcCartSummaryItemTotalLabel = "CartSummary-module--bcCartSummaryItemTotalLabel--02f1a";
export var bcCartSummaryItemValue = "CartSummary-module--bcCartSummaryItemValue--64785";
export var bcCartSummaryTitle = "CartSummary-module--bcCartSummaryTitle--cfe23";
export var bcCartSummaryToggle = "CartSummary-module--bcCartSummaryToggle--67a8a";
export var bcCartSummaryTop = "CartSummary-module--bcCartSummaryTop--a5908";
export var bcDiscount = "CartSummary-module--bcDiscount--cd92e";
export var bcMiniCartSummary = "CartSummary-module--bcMiniCartSummary--309d7";
export var buttonWrapp = "CartSummary-module--buttonWrapp--7733a";
export var promoLabel = "CartSummary-module--promoLabel--9db70";
export var subtotal = "CartSummary-module--subtotal--3c02f";
export var totalSavings = "CartSummary-module--totalSavings--8d379";